export default [
  {
    path: "/admin/user/:id",
    name: "adminUser",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/User.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman', 'userManager','habManager']
      }
    }
  },
  {
    path: "/admin/user/password/:id",
    name: "/admin/user/password/:id",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/Password.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman', 'userManager']
      }
    }
  },
  {
    path: "/admin/user/notifications/:id",
    name: "/admin/user/notifications/:id",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/Notifications.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman']
      }
    }
  },
  {
    path: "/admin/users",
    name: "/admin/users",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/Main.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'boardMember', 'chairman', 'userManager', 'habManager']
      }
    }
  },
  {
    path: "/admin/apartment/edit/:id",
    name: "/admin/apartment/edit/:id",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/HousingCompanies/Apartment/Edit.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'boardMember', 'chairman', 'userManager']
      },
      breadcrumb: [
        { name: "User Management", link: '/admin/users' },
        { name: "Apartment" }
      ]
    }
  },
  {
    path: "/admin/apartment/:id",
    name: "adminApartment",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/Apartment.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'boardMember', 'chairman', 'userManager', 'ceeMaintenanceManager']
      },
      breadcrumb: [
        { name: "User Management", link: '/admin/users' },
        { name: "Apartment" }
      ]
    }
  },
  {
    path: "/admin/users/new/:hcid/:aptid",
    name: "addAptUser",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/New.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman', 'userManager']
      }
    }
  },
  {
    path: "/admin/users/new/:hcid",
    name: "addHcUser",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/New.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'partner', 'boardMember', 'chairman', 'userManager']
      }
    }
  },
  {
    path: "/admin/users/new",
    name: "newUser",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/New.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default",
      accessPolicies: {
        roles: ['yitUser', 'propertyManager', 'maintenanceManager', 'boardMember', 'partner', 'chairman', 'userManager', 'habManager']
      }
    }
  },
  {
    path: "/admin/users/:id/add-role",
    name: "addUserRole",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/Admin/Users/Roles.vue"
      ),
    meta: {
      admin: true,
      layout: "admin-default"
    }
  },
  {
    path: "/my-apartment/users",
    name: "/my-apartment/users",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/Users.vue"
      ),
    meta: {
      sectionTitle: "navigation.manage.users",
      title: "navigation.manage.users"
    }
  },
  {
    path: "/my-apartment/users/new",
    name: "addApartmentUser",
    component: () =>
      import(
        /* webpackChunkName: "my-apartment" */ "@/views/Apartment/New.vue"
      ),
    meta: {
      sectionTitle: "navigation.manage.users",
      title: "navigation.manage.users"
    }
  }
]